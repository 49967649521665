import { get, post } from "../utils/http"

function getRecommendRestaurants(num , limit) {
    const url = "/v1/restaurant/recommend";
    const data = {
        num,
        limit
    }
    return post({ url , data });
}

function getRestaurantDetails(restaurantId) {
    const url = "/v1/restaurant/" + restaurantId;
    return get({ url });
}

function getRestaurantOpeningHours(restaurantId) {
    const url = "/v1/restaurant/opening-hours/" + restaurantId;
    return get({ url });
}

function getRestaurantBookingOpeningHours(restaurantId) {
    const url = "/v1/restaurant/booking-opening-hours/" + restaurantId;
    return get({ url });
}

function searchRestaurants(keyword, cityId, styleId, priceId, num , limit) {
    const url = "/v1/restaurant/s";
    const data = {
        keyword,
        cityId,
        styleId,
        priceId,
        num,
        limit
    }
    return post({ url, data });
}

export default {
    getRecommendRestaurants,
    getRestaurantDetails,
    searchRestaurants,
    getRestaurantOpeningHours,
    getRestaurantBookingOpeningHours
}