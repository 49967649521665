import RestaurantService from "../api/restaurant-service";
import RestaurantBookingService from "../api/restaurant-booking-service";
import Member from "../api/member";
import { parseTime, getWeekDay, isPhoneNumber } from "../utils"
import { goEcOrderDetails } from "../utils/ec"

function createPersonCount(max) {
    let persons = [];
    for (let i = 1; i <= max; i++) {
        persons.push(i)
    }
    return persons;
}

function buildAvaliableBookingDates(times) {
    let dates = [];
    for (let i = 0; i < times.length; i++) {
        let current = new Date(times[i].date);
        let date = (current.getMonth() + 1) + "." + current.getDate();
        let day = getWeekDay(current);
        let avaliable = times[i].avaliable;
        let paused = times[i].paused;
        if (!paused) {
            dates.push({
                date,
                day,
                avaliable,
                paused,
                dateObject: current
            })
        }
    }
    return dates;
}

function buildAvaliableBookingTimes(state, selectedDate) {
    let openingHours = state.openingHours;
    let restaurant = state.restaurant;
    if (!openingHours || openingHours.length == 0) {
        return createTimes(restaurant.openHours || "10:00", restaurant.closeHours || "22:00");
    }
    let hours = [];
    let day = selectedDate.getDay() == 0 ? 7 : selectedDate.getDay();
    for (let i = 0; i < openingHours.length; i++) {
        let openingHour = openingHours[i];
        if (openingHour.begin <= day && openingHour.end >= day) {
            hours = addOpeningHours(hours, openingHour.open, openingHour.close);
            hours = addOpeningHours(hours, openingHour.open2, openingHour.close2);
            hours = addOpeningHours(hours, openingHour.open3, openingHour.close3);
            break;
        }
    }
    return hours;
}

function addOpeningHours(hours, open, close) {
    if (!open && !close) {
        return hours;
    }
    let times = createTimes(open, close);
    for (let i = 0; i < times.length; i++) {
        hours.push(times[i]);
    }
    return hours;
}

function createTimes(open, close) {
    let min = parseInt(open.split(":")[0]);
    let max = parseInt(close.split(":")[0]);
    let topenMinute = parseInt(open.split(":")[1]);
    let tcloseMinute = parseInt(close.split(":")[1]);
    let times = [];
    let start = topenMinute > 0 ? min * 2 + 1 : min * 2;
    for (let i = start; i <= max * 2; i++) {
        let hours = parseInt(i / 2)
        times.push(hours + (i % 2 == 0 ? ":00" : ":30"));
    }
    if (tcloseMinute > 0) {
        times.push(max + ":30")
    }
    return times;
}

const onSubmit = state => {
    return new Promise((reslove, reject) => {
        if (!state.bookingDate) {
            reject({
                bookingDateError: true
            });
        } else if (!state.bookingTime) {
            reject({
                bookingTimeError: true
            });
        } else if (!state.telphone || !isPhoneNumber(state.telphone)) {
            reject({
                bookingTelephoneError: true
            });
        } else {
            let bookingTime = state.bookingDateObject;
            bookingTime.setHours(parseInt(state.bookingTime.split(":")[0]))
            bookingTime.setMinutes(parseInt(state.bookingTime.split(":")[1]));
            bookingTime.setSeconds(0)
            RestaurantBookingService.restaurantBooking({
                restaurantId: state.restaurantId,
                personName: state.personName,
                telphone: state.telphone,
                personNumber: state.personCount,
                bookingTime: parseTime(bookingTime),
                seat: state.seat,
                remark: state.remark,
                allergy: state.allergy
            }).then(reslove, reject);
        }
    })
}
const onMounted = state => {
    RestaurantService.getRestaurantDetails(state.restaurantId)
        .then(r => state.restaurant = r)
    state.personCounts = createPersonCount(9);
    // state.times = createTimes(10, 24);
    // state.dates = createDates(60);
    // state.bookingDate = createDateFormat(2).date;
    // state.seats = ["大厅", "包厢"]
    RestaurantService.getRestaurantBookingOpeningHours(state.restaurantId)
        .then(hours => state.openingHours = hours);
    RestaurantBookingService.getAvaliableBookingTimes(state.restaurantId)
        .then(times => state.dates = buildAvaliableBookingDates(times))
    Member.getMemberInfo().then(info => {
        state.personName = info.name;
        state.telphone = info.telphone;
    });
}
// const onLoadScript = () => initEcScript(User.getClient());
const orderDetails = (orderNo) => goEcOrderDetails(orderNo);
const buildTimes = (state, selectedDate) => buildAvaliableBookingTimes(state, selectedDate);

export default {
    onSubmit,
    onMounted,
    // onLoadScript,
    orderDetails,
    buildTimes,
}