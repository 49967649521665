<template>
  <view class="index restaurant-booking">
    <!-- <van-nav-bar
      title="餐厅预定"
      left-arrow
      @click-left="onBack"
    /> -->
    <van-form @submit="onSubmit" class="order-form">

      <h5><van-icon name="user-o" />预定人</h5>
      <van-field class="input-field-style"
        v-model="personName"
        rows="1"
        autosize
        type="textarea"
        placeholder="请输入"
      />

      <h5><van-icon name="phone-o" />联系电话 <small class="telphone-desc">请填写常用手机号码并留意接听</small></h5>
      <van-field class="input-field-style"
        v-model="telphone"
        rows="1"
        autosize
        type="textarea"
        placeholder="请输入"
      />

      <h5><van-icon name="friends-o" />选择人数 <small class="telphone-desc">往左滑动可选择其他的人数<van-icon name="arrow" /> </small></h5>
      <div class="select-style">
        <div v-for="p in personCounts" 
            :key="p" 
            :class="isSelected(p, personCount)"
            @click="personCount = p">
            <span v-if="p > 8">8+人</span>
            <span v-else>{{p}}人</span>
        </div>
      </div>

      <h5><van-icon name="calendar-o" />选择日期 <small class="telphone-desc">往左滑动可选择其他的日期<van-icon name="arrow" /> </small></h5>
      <div class="select-style">
        <div v-for="d in dates" 
            :key="d" 
            class= "date-box"
            :class="isSelected(d.date, bookingDate)"
            @click="onSelectedDate(d)">
            <span class="select-date">{{d.date}}</span>
            <span class="select-day">{{d.day}}</span>
        </div>
      </div>

      <h5><van-icon name="underway-o" />选择时间 <small class="telphone-desc">往左滑动可选择其他的时间<van-icon name="arrow" /> </small></h5>
      <div class="select-style">
        <div v-for="t in times" 
            :key="t" 
            :class="isSelected(t, bookingTime)"
            @click="bookingTime = t">
            <span>{{t}}</span>
        </div>
      </div>
      <!-- <h5><van-icon name="hotel-o" />座位选择</h5>
      <div class="select-style">
        <div v-for="s in seats" 
            :key="s" 
            :class="isSelected(seat, s)"
            @click="seat = s">
            <span>{{s}}</span>
        </div>
      </div> -->

      <!-- <h5><van-icon name="flower-o" />过敏</h5>
      <van-field class="input-field-style"
        v-model="allergy"
        rows="1"
        autosize
        type="textarea"
        placeholder="请输入"
      /> -->
      <h5><van-icon name="smile-comment-o" />备注</h5>
      <van-field
        v-model="remark"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入备注"
        class="input-textarea-style"
        show-word-limit
      />

      <van-cell class="ec-cell-desc">
        <small class="desc"> * 请事先了解并遵照当地及餐厅的防疫政策要求进入餐厅用餐，谢谢。 </small>
      </van-cell>

      <div style="margin-top: 16px; margin-bottom: 16px;">
        <van-button block type="primary" native-type="submit">提交</van-button>
      </div>
    </van-form>

    <van-popup v-model:show="submitSuccessShow" round position="bottom" @click-overlay="onBack">
      <div class="success-card">
        <van-icon name="success" />
        <h3>提交成功</h3>
        <div class="success-info">
          预定信息已成功提交，待商家确认后会有确认短信通知您。
        </div>
        
        <van-row>
          <van-col span="12">
            <van-button plain type="primary" @click="onRestaurantHome">继续预定餐厅</van-button>
          </van-col>
          <van-col span="12">
            <van-button plain type="primary" @click="onRestaurantDetails">查看预定详情</van-button>
          </van-col>
        </van-row>
      </div>
    </van-popup>
  </view>
</template>

<script>
import { reactive, toRefs , onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from 'vant';
import Controller from "./restaurant-booking-controller";
import "../css/restaurant-booking.scss";
import { loading, clearLoading } from '../utils/loading';

export default {
  created() {
    // Controller.onLoadScript();
  },
  setup() {
    const state = reactive({
      restaurant: {},
      restaurantId: 0,
      personCount: 2,
      personCounts: [],
      bookingDate: null,
      bookingDateObject: null,
      dates: [],
      bookingTime: null,
      times: [],
      seat: "",
      seats: [],
      remark: "",
      allergy: "",
      personName: "" ,
      telphone: "",
      submitSuccessShow: false,
      bookingId: 0,
      thirdPartyOrderId: null
    });
    const { id } = useRoute().params;
    const router = useRouter();
    state.restaurantId = id;
    const onSubmit = () => { 
      loading();
      Controller.onSubmit(state).then((result) => {
        state.submitSuccessShow = true ;
        state.bookingId = result.bookingId;
        state.thirdPartyOrderId = result.thirdPartyOrderId;
        clearLoading();
    }, err => {
        clearLoading();
        if(err.bookingDateError) {
            Toast.fail("请选择日期");
        } else if(err.bookingTimeError) {
            Toast.fail("请选择时间");
        } else if(err.bookingTelephoneError) {
            Toast.fail("请输入正确的电话号码");
        } else {
            Toast.fail("出错了，请联系相关客服。");
        }
        //Toast.fail(err.message);
    })};
    const onBack = () => {
        state.submitSuccessShow = false;
        router.push("/restaurant/" + id);
    }
    const onRestaurantDetails = () => {
        state.submitSuccessShow = false;
        if(!state.thirdPartyOrderId) {
           router.push("/restaurant-booking-details/" + state.bookingId)
           return ;
        }
        Controller.orderDetails(state.thirdPartyOrderId).then(
          ()=> state.submitSuccessShow = false, 
          ()=> router.push("/restaurant-booking-details/" + state.bookingId))
    }
    const onRestaurantHome = () => {
        state.submitSuccessShow = false;
        router.push("/restaurant");
    }
    const onSelectedDate =(value) => {
        state.bookingDate = value.date;
        state.bookingDateObject = value.dateObject;
        state.bookingTime = null;
        state.times = Controller.buildTimes(state, value.dateObject);
    }
    const isSelected = (current, value) => {
        if (!current || !value) {
            return "";
        }
        return current == value ? "selected" : "";
    }
    onMounted(() => Controller.onMounted(state))
    return {
      ...toRefs(state),
      onSubmit,
      onBack,
      onRestaurantHome,
      onRestaurantDetails,
      onSelectedDate,
      isSelected
    };
  },
};
</script>
